<template>
    <div id="default-layout">
        <main :class="$style.main">
            <div :class="$style.content">
                <slot />
            </div>
        </main>
        <div id="modals" />
    </div>
</template>

<style module lang="scss">
.main {
    display: flex;
    height: 100vh;
    align-items: stretch;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 48px 80px;

    @media screen and (width <= 620px) {
        padding: 24px 24px 80px;
    }

    @media screen and (width <= 480px) {
        padding: 16px 16px 80px;
    }
}
</style>
